@import "src/styles/lib";

.EditWebhook {
    width: 100%;
    padding: 0;
    margin-bottom: rem(100);
    &__title {
        font-size: rem(24);
        line-height: rem(31);
        color: #111827;
        margin-bottom: rem(37);
        margin-top: rem(80);
        font-weight: 700;
    }
  
    &__form-control {
        margin-bottom: rem(32);
    }
    &__input-group-container {
        position: relative;
    }
    &__input-group-container2 {
        display: flex;
        align-items: center;
        margin-bottom: rem(32);
    }
    &__input-value {
        color: $secondary-color;
        margin-left: rem(-5);
    }
    &__input-label2 {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.3px;
        font-weight: 600;
        color: #111827;
        margin-right: rem(60);
    }
    &__input-label {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.3px;
        font-weight: 600;
        color: #111827;
        margin-bottom: rem(16);
    }
    &__invalid-feedback {
        position: absolute;
        bottom: rem(12);
        left: rem(5);
        font-size: rem(11);
        color: #f06548;
    }
    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: rem(40);
    }
    &__discard-btn {
        width: rem(120);
        height: rem(48);
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: 0.2px;
        transition: all 200ms ease-in-out;
        margin-right: rem(15);
    }
    &__save-btn {
        width: rem(120);
        height: rem(48);
        color: $white;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: 0.2px;
        transition: all 200ms ease-in-out;
    }
    &__input-devices-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        min-height: rem(56);
        height: 100%;
        min-width: rem(59);
        border: none;
        border-radius: rem(12);
        padding: rem(16);
        background: darken(#fafafa, 10%);
        cursor: not-allowed;
        color: #111827;
        opacity: 0.4;
    }
    &__input-devices {
       margin-left: rem(2);
       margin-bottom: rem(5);
    }
    
}