@import "src/styles/lib";

.Login {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 52.5% 47.5%;
    &__footer {
        margin-top: auto;
        margin-bottom: rem(40);
        left: 25%;
        right: 25%;
        font-size: rem(16);
        line-height: rem(24);
        color: $secondary-color;
        text-align: center;
        &-link {
            color: $primary-color;
            font-weight: 700;
            margin-left: rem(5);
            text-decoration: none;
            &:hover {
                color: darken($primary-color, 20%); 
            }
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 rem(118);
        position: relative;
        background-color: $white;
    }
    &__wrapper {
      margin-top: auto;
      margin-bottom: rem(10);
    }
    &__title {
        font-size: rem(32);
        line-height: rem(40);
        font-weight: 700;
        color: #111827;
        margin-bottom: rem(40);
    }
  
}
@include tablet-big {
    .Login {
        &__container {
            padding: 0 rem(60);
        } 
    }
}

@include tablet {
    .Login {
        grid-template-columns: 100%; 
        height: 100vh;
        &__container {
            justify-content: unset;
        }
        &__wrapper {
            margin-top: 0;
        }
        &__logo-wrapper {
           margin: rem(87) auto rem(70);
        }
        &__title {
            font-size: rem(26);
            line-height: rem(32);
            margin-bottom: rem(14);
            text-align: center;
        }
        &__subtitle {
            font-size: rem(18);
            line-height: rem(23);
            margin-bottom: rem(52);
            text-align: center;
        }
        &__separator {
            margin-top: rem(45);
            margin-bottom: rem(24);
            border-top: 1px solid #d3d3d3;
            text-align: center;
            span {
                color: #491F61; 
                font-size: 12px;
                line-height: rem(23);
                background-color: $white;
                text-align: center;
                margin: -12px auto 0;
                display: block;
                width: 30px;
            }
        }
        &__footer-btn {
            margin-bottom: rem(15);
        }
    }
}
@include mobile {
    .Login {
        grid-template-columns: 100%; 
        height: 100vh;
        &__container {
            justify-content: unset;
            padding: 0 rem(40);
        }
        &__logo-wrapper {
           margin: rem(80) auto rem(60);
        }
        &__wrapper {
            margin-top: 0;
        }
        &__title {
            font-size: rem(26);
            line-height: rem(32);
            margin-bottom: rem(14);
            text-align: center;
        }
        &__subtitle {
            font-size: rem(18);
            line-height: rem(23);
            margin-bottom: rem(45);
            margin-top: 0;
            text-align: center;
        }
        &__separator {
            margin-top: rem(45);
            margin-bottom: rem(24);
            border-top: 1px solid #d3d3d3;
            text-align: center;
            span {
                color: #491F61; 
                font-size: 12px;
                line-height: rem(23);
                background-color: $white;
                text-align: center;
                margin: -12px auto 0;
                display: block;
                width: 30px;
            }
        }
        &__footer-btn {
            margin-bottom: rem(40);
        }
    }
}