.EditOrganization {
  min-height: 100vh;
  background-color: #F5F5F5;
  padding: 2rem;

  &__container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 2rem;
  }

  &__title {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    color: #111827;
    font-weight: 700;
  }

  &__form-group {
    margin-bottom: 1.5rem;
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    color: #111827;
    margin-bottom: 1rem;

  }

  &__error {
    color: #eb0000;
    font-size: 0.85rem;
    margin-top: 0.3rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}
