@import "src/styles/lib";

.Pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__dropdown {
    position: relative;

    &-header {
      background-color: #e7f7ef;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: rem(5) rem(10);
      border-radius: rem(12);
      color: #0caf60;

      &:hover {
        cursor: pointer;
        background-color: darken(#e7f7ef, 10%);
        color: #0caf60;
      }
      &-label {
        margin-right: rem(5);
      }
      &-icon {
        width: 20px;
      }
    }

    &-body {
      background-color: #e7f7ef;
      position: absolute;
      top: -40px;
      width: 100%;
      border-radius: rem(12);

      &-item {
        padding: rem(5) rem(10);
        color: #a0aebf;

        &:hover {
          cursor: pointer;
          color: #0caf60;
          background-color: darken(#e7f7ef, 10%);
        }

        &:first-child {
          border-radius: rem(12) rem(12) 0 0;
        }
        &:last-child {
          border-radius: 0 0 rem(12) rem(12);
        }
      }
    }
  }

  &__content {
    display: flex;
  }

  &__select {
    margin-left: rem(20);
    display: flex;
    align-items: center;
  }

  &__description {
    color: #718096;
    font-size: rem(14);
    line-height: rem(22.4);
    display: flex;
    align-items: center;
    margin-right: rem(16);
  }

  &__link {
    height: rem(40);
    width: rem(40);
    font-size: rem(14);
    border-radius: rem(12);
    color: #a0aec0;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid #EEEFF2;
    cursor: pointer;
    margin-left: rem(1);
    &:hover {
      color: #0caf60;
    }

    &--page {
      border: none !important;
      outline: none !important;
      &:hover {
        background: #e7f7ef;
        color: #0caf60;
      }

      &-active {
        background: #e7f7ef;
        color: #0caf60;
      }
    }
  }

  &__icon {
    > svg {
      width: rem(20);
      &:hover path {
        color: #0caf60;
      }
    }

    &--double {
      > svg {
        height: rem(7);
        width: rem(7);
      }
    }
  }
}

@include tablet {
  .Pagination {
    margin-top: 0;
    margin-bottom: 10px;
    margin-right: 15px;
    &__description {
      display: none;
    }
    &__link {
      height: rem(40);
      width: rem(40);
      font-size: rem(12);
      border-radius: rem(4);
    }
  }
}

@include mobile {
  .Pagination {
    margin-top: 0;
    margin-bottom: 10px;
    margin-right: 15px;
    &__description {
      display: none;
    }
    &__link {
      height: rem(26);
      width: rem(26);
      font-size: rem(12);
    }
    &__select {
      margin-left: 0;
    }
    &__icon {
      > svg {
        width: rem(16);
      }
    }
  }
}
