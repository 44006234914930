@import "src/styles/lib";

.NewPassword {
    width: 100%;
    height: 100vh;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__container {
        max-width: rem(552);
        width: 100%;
        padding: rem(40);
        background-color: $white;
        border-radius: rem(24);
        margin: rem(10);
    }
 
    &__icon {
        width: rem(12);
        height: rem(12);
        display: block;
        margin-left: auto;
        cursor: pointer;
    }
    &__title {
        font-size: rem(32);
        line-height: rem(40);
        font-weight: 700;
        color: #111827;
        margin-bottom: rem(16);
        margin-top: 0;
    }
    &__subtitle {
        font-size: rem(18);
        line-height: rem(27);
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #718096;
        margin-bottom: rem(40);
    }
    &__input-wrapper {
        margin-bottom: rem(34);
    }
    &__btn {
        margin-top: rem(22);
    }
    &__input-group-container {
        position: relative;
    }
    &__login {
        font-size: rem(14);
        line-height: rem(22.4);
        font-weight: 700;
        letter-spacing: 0.2px;
        color: $primary-color;
        text-decoration: none;
        display: block;
       }
   
    &__invalid-feedback {
        position: absolute;
        bottom: -20px;
        left: rem(5);
        font-size: rem(12);
        color: #f06548;
        &--password {
            position: absolute;
            bottom: -30px; 
            left: rem(5);
            font-size: rem(12);
            color: #f06548; 
        }
    }
   
}

@include mobile {
    .NewPassword {
        &__container {
            padding: rem(30);
        }
        &__title, &__subtitle {
            text-align: center;
        }
        &__input-wrapper {
            margin-top: rem(44);
        }
        
         
    &__invalid-feedback {
        position: absolute;
        font-size: rem(10);
        &--password {
            position: absolute;
            bottom: -36px; 
            font-size: rem(10);
        }
    }
    }
}