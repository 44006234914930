@import "src/styles/lib";

.VerificationCountChart {
    max-width: rem(1600);
    width: 100%;
    height: rem(650);
    box-shadow: 0px 1px 2px #38414A26;
    border: 1px solid #E9EBEC;
    border-radius: rem(8);
    background-color: #ffffff;
    margin: 0 auto;
  &__chart {
    padding: rem(20) rem(40) rem(40) rem(40);
  }
  &__no-data {
    height: rem(465);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(20);
    &--message {
      color: #878a99;
      font-size: rem(14);
      line-height: rem(20);
      span {
        color: #000000;
        font-weight: 500;
      }
    }
    a {
      text-decoration: none;
    }
    &--device {
      margin-top: rem(100);
      color: #878a99;
      font-size: rem(16);
      line-height: rem(20);
      letter-spacing: 0.4px;
    }
  }
  &__header-buttons {
    display: flex;
    align-items: center;
  }
  &__header-text {
      font-size: rem(14);
      color: #ADB5BD;
      margin-right: rem(10);
  }
  &__selectbox1, &__selectbox2, &__selectbox3  {
    margin-right: rem(10);
    padding: 0;
    min-width: rem(80);
  }
  &__selectbox-header {
    padding: rem(4) rem(3) rem(3) rem(8);
  }
  &__device-selectbox {
    min-width: rem(150);
    margin-right: rem(10);
    height: 100%;
  }
   
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9EBEC;
    padding: rem(25) rem(15);
    &--small {
        border: none;
        flex-direction: column;
        padding: 0;
    }
}
&__loader {
  padding: rem(20) rem(40) rem(40) rem(70);
}
}

@include mobile {
    .VerificationCountChart {
        height: rem(700);
        &__header {
            padding: rem(20) rem(20) 0;
            border: none;
            flex-direction: column;
            align-items: flex-start;
        }
        &__header-buttons {
            margin-bottom: rem(20);
        }
        &__chart {
            padding: 0 rem(10) rem(20) rem(20);
        }
    }
}