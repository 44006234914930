@import "src/styles/lib";

.LoginForm {
    &__input-group-container{
        position: relative;
    }
    &__btn {
        margin-top: rem(32);
    }
    &__forgot-password {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.2px;
        text-align: right;
        color: $primary-color;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: darken($primary-color, 20%); 
        }
    }
    &__invalid-feedback {
        position: absolute;
        bottom: rem(5);
        left: rem(5);
        font-size: rem(12);
        color: #f06548;
    }
    &__form-control {
        margin-bottom: rem(24);
    }
    &__forgot-password-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: rem(5);
    }
}

@include mobile {
    .LoginForm {
        &__forgot-password {
            font-size: 12px;
        } 
    }
}