@import "src/styles/lib";

.SecureKey {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(24);
  }
  &__header-title {
    font-size: rem(22);
    font-weight: bold;
    margin: 0;
    color: #111827;
  }
  &__header-button {
    max-width: rem(129);
    background-color: #0d99ff;
    color: $white;
    padding: rem(8) rem(20);
    margin-left: rem(21);
    &:hover {
      background: darken(#0d99ff, 15%);
    }
  }
  &__container {
    background: $white;
    border-radius: rem(16);
    padding: rem(24);
  }
  &__filter-icon {
    width: rem(24);
    height: rem(24);
    color: #a0aec0;
    margin-right: rem(8);
  }
  &__filter-container {
    margin-left: rem(16);
    color: #718096;
    background-color: #fafafa;
    border-radius: rem(12);
    padding: rem(16);
    line-height: rem(24);
    letter-spacing: 0.2px;
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
}
