@import "src/styles/lib";

.HamburgerButton {
  padding: rem(10) rem(10) rem(10) 0;

  &:hover {
    cursor: pointer;
  }

  &:hover > &__icon {
    opacity: 1;
  }

  &__icon {
    width: rem(20);
    height: rem(14);
    opacity: 0.8;
    transition: 0.3s;
  }
}