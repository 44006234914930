@import "src/styles/lib";

.AccountCreated {
    position: relative;

  &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #F5F5F5;
      z-index: 1000;
  }
  &__wrapper {
      max-width: rem(552);
      width: 100%;
      padding: rem(40) rem(40) rem(25) rem(40);
      background-color: $white;
      border-radius: rem(24);
      margin: rem(20);
  }
  &__message {
    margin-top: rem(25);
    margin-bottom: 0;
    span {
        color: #718096;
    }
  }
}