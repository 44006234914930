@import "src/styles/lib";

.Devices {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(24);
  }
  &__header-container {
    display: flex;
    align-items: center;
  }
  &__header-title {
    font-size: rem(22);
    //line-height: rem(31);
    margin: 0;
    color: #111827;
  }
  &__header-button {
    max-width: rem(129);
    background-color: #0d99ff;
    color: $white;
    padding: rem(8) rem(20);
    margin-left: rem(21);
    &:hover {
      background: darken(#0d99ff, 15%);
    }
  }
  &__header-layout-icons {
    background-color: $white;
    border-radius: rem(12);
    padding: rem(8);
    display: flex;
    align-items: center;
  }
  &__header-icon,
  &__filter-icon {
    width: rem(24);
    height: rem(24);
    color: #a0aec0;
  }
  &__tablet-loader {
    &--gone {
      display: none;
    }
  }
  &__icon-wrapper {
    padding: rem(8);
    border-radius: rem(8);
    background-color: $white;
    transition: all 300ms ease-in-out;
    &--active {
      background-color: #e4f4ff;
      cursor: pointer;
      .Devices__header-icon {
        color: #0d99ff;
      }
    }
    &:hover {
      background-color: #e4f4ff;
      cursor: pointer;
      .Devices__header-icon {
        color: #0d99ff;
      }
    }
  }
  &__container {
    background: $white;
    border-radius: rem(16);
    padding: rem(24);
  }
  &__filter-icon {
    margin-right: rem(8);
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  &__filter-container {
    margin-left: rem(16);
    color: #718096;
    background-color: #fafafa;
    border-radius: rem(12);
    padding: rem(16);
    line-height: rem(24);
    letter-spacing: 0.2px;
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(20);
    margin: rem(38) auto;
  }
}

@include mobile {
  .Devices {
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__header-title {
      margin-bottom: rem(20);
    }

    &__header-container {
      display: flex;
      justify-content: space-between;
    }
  }
}
