@import "src/styles/lib";

.Device {
  background: #fafafa;
  padding: rem(46) rem(35) rem(35);
  border-radius: rem(12);
  text-align: center;
  position: relative;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  // max-width: rem(335);
  width: rem(335);
  &:hover {
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .Device__delete-icon {
      visibility: visible;
      svg {
        color: $error;
      }
    }
    .Device__qrcode-icon {
      visibility: visible;
    }
  }

  &__back {
    position: absolute;
    padding: rem(5);
    left: rem(20);
    top: rem(20);

    &-icon {
      width: rem(20);

      svg {
        color: $primary-color;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: auto;
    overflow-wrap: break-word;
    padding: rem(26) 0;
  }
  &__image {
    width: rem(55);
    height: fit-content;
    margin-bottom: rem(16);
  }
  &__image-placeholder {
    width: rem(60);
    height: rem(60);
    border-radius: 50%;
    margin-bottom: rem(16);
    color: $white;
    background-color: #a0aec0;
    text-transform: uppercase;
    font-size: rem(20);
    padding-top: rem(19);
  }
  &__delete-icon {
    width: rem(14);
    height: rem(16);
    position: absolute;
    right: 20px;
    top: 20px;
    visibility: hidden;
    svg {
      color: $error;
    }
  }
  &__scan-wrapper {
    background-color: $primary-color;
    height: rem(48);
    padding: 10px;
  }

  &__qr-wrapper {
    width: rem(290);
    height: auto;
    border: 3px solid $primary-color;
    outline: 3px dashed $primary-color;
    outline-offset: rem(2.5);
    margin: 0 auto;
    border-radius: 8px;
  }
  &__scan-with {
    width: fit-content;
    height: auto;
  }

  &__qrcode-icon {
    width: rem(45);
    height: rem(40);
    position: absolute;
    visibility: hidden;
    left: 10px;
    top: 15px;
  }
  &__title {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: 0.3px;
    font-weight: 700;
    color: #111827;
    margin: 0;
    word-break: break-all;
  }
  &__subtitle,
  &__subtitle2, &__id {
    font-size: rem(14);
    line-height: rem(22.4);
    font-weight: 400;
    margin-bottom: 0;
    margin-top: rem(11);
  }
  &__id {
    margin-top: rem(15);
    background-color: #f0f2f9;
    color: #4f5676;
    padding: rem(2) rem(8);
    border-radius: rem(4);
    letter-spacing: 0.05em;
  }
  &__subtitle {
    flex: 1 0 auto;
    color: #0caf60;
  }
  &__subtitle2 {
    color: #718096;
  }
  &__button {
    width: rem(119);
    height: rem(50);
    color: #0d99ff;
    border: 1px solid #0d99ff;
    font-size: rem(14);
    font-weight: 500;
    margin: rem(15) auto 0;
    transition: all 300ms ease-in-out;

    &:hover {
      background-color: #0d99ff;
      color: $white;
    }
  }
  &__buttons-wrapper {
    display: flex;
  }
  &__button-scan {
    margin-right: rem(10);
  }
}
