@import "src/styles/lib";

html,
body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: $proxima-nova;
}

body {
  overflow-y: scroll;
  background: #fafafa;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
  align-items: center;
}

.page-spinner {
  margin: rem(150) auto;
  display: block;
  width: 80px;
  height: 80px;
}
.page-spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $primary-color;
  border-color: $primary-color transparent $primary-color transparent;
  animation: spinning 1.2s linear infinite;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
