@import "src/styles/lib";

.flickity-prev-next-button.previous {
  z-index: 20 !important;
  pointer-events: fill;
}

.flickity-prev-next-button.next {
  z-index: 20 !important;
  pointer-events: fill;
}

.flickityBase {
  z-index: 2;
}

.flickity-page-dots .flickity-page-dot {
  background: #b3b3b3;
}
.flickity-page-dots .flickity-page-dot:hover {
  background: $white;
}

.flickity-page-dots.flickity-page-dots .is-selected {
  background: $white;
}