@import "src/styles/lib";

.LevelOne {
  position: relative;
  display: flex;
  justify-content: center;

  // Head ------------------------------------

  &__header {
    height: rem(40);
    width: rem(200);
    color: #A0AEC0;
    display: flex;
    align-items: center;

    &-collapsed {
      width: rem(70);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-label {
      padding: 0;
      font-size: rem(12);
      line-height: rem(19.2);
      text-transform: uppercase;
      color: #718096;
    }
    &-icon {
      display: flex;
    }
    &-circle {
      width: rem(3);
      height: rem(3);
      margin: rem(1);
      background-color: #838fb9;
      border-radius: 50%;
    }
  }

  &__head {
    padding-top: rem(14);
    padding-bottom: rem(14);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: rem(200);
    color: #A0AEC0;
    &:hover {
      color: #1E2945;
    }

    &--collapsed {
      display: flex;
      justify-content: center;
      width: rem(70);
    }
  }

  &__head-container {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: rem(24);

    & > svg {
      width: rem(24);
      height: auto;
      max-height: rem(35);
    }
  }

  &__icon--active svg path {
      color: $primary-color;
  }

  &__label {
    font-size: rem(16);
    margin-left: rem(16);
    line-height: rem(24);
    font-weight: 400;
    letter-spacing: 0.3px;

    &--active {
      color: $primary-color;
      font-weight: 700;
    }
  }

  &__arrow {
    width: rem(15);
    rotate: -90deg;

    &--open {
      rotate: 0deg;
      color: #A0AEC0;
    }
  }

  // Body ------------------------------------

  &__body {
    display: none;
    background-color: $white;
    width: rem(200);

    &--open {
      display: block;
    }

    &--collapsed-open {
      display: block;
      position: absolute;
      top: 0;
      left: rem(70);
      z-index: 1000;
    }

    &-head {
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rem(15) rem(5);
      background: $white;
      box-shadow: rem(1) rem(1) rem(2) #38414a26;

      &:hover {
        cursor: pointer;
      }
    }

    &-label {
      font-size: rem(15);
    }

    &-arrow {
      width: rem(15);
    }
  }
}