@import "src/styles/lib";

.CreateOrganization {
   width: 100%;
   height: 100%;
   background-color: #F5F5F5;
   padding: rem(60) rem(10) rem(94);
    &__invalid-feedback {
      position: absolute;
      bottom: rem(5);
      left: rem(5);
      font-size: rem(12);
      color: #f06548;
   }
   &__input-group-container {
      position: relative;
   }
   &__header {
      text-align: center;
      padding: 0 rem(5);
   }
   &__title {
      font-size: rem(32);
      line-height: rem(40);
      font-weight: 700;
      color: $primary-color;
      margin-bottom: rem(12);
      margin-top: 0;
   }
   &__subtitle {
      font-size: rem(18);
      line-height: rem(27);
      font-weight: 500;
      color: #718096;
      margin-bottom: rem(32);
   }
   &__form {
      border: 1px solid #EEEFF2;
      border-radius: rem(24);
      padding: rem(32);
      max-width: rem(818);
      width: 100%;
      background-color: $white;
      margin: 0 auto;
   }
   &__input-container {
      margin-bottom: rem(24);
   }
   &__input-container-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(24);
   }
   &__label {
      font-size: rem(16);
      line-height: rem(24);
      font-weight: 700;
      color: #111827;
      margin-bottom: rem(12);
   }
   &__image-container {
      margin-top: rem(24);
      margin-bottom: rem(24);
      display: flex;
   }
   &__image-info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }
   &__image-placeholder {
      max-width: rem(88);
      width: fit-content;
      height: rem(88);
      border-radius: 50%;
      margin-right: rem(24);
   }
   &__image-placeholder2 {
      width: rem(88);
      height: rem(88);
      border-radius: 50%;
      background-color: #e8e8e8;
      margin-right: rem(24);
   }
   &__image-info {
      font-size: rem(14);
      line-height: rem(22.4);
      color: #718096;
   }
   &__upload-btn {
      max-width: rem(123);
      width: 100%;
      height: rem(48);
      background-color: #0D99FF;
      font-size: rem(14);
      line-height: rem(22.4);
      letter-spacing: 0.2px;
      &:hover {
        background: darken(#0D99FF, 15%);
      }
   }
   &__submit-btn {
      max-width: rem(150);
      width: 100%;
      margin-left: auto;
      margin-top: rem(32);
   }
}

@include mobile {
    .CreateOrganization {
        padding-top: rem(70);
        &__input-container-row {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0;
        }
        &__form {
            max-width: 400px;
            width: 100%;
            padding: rem(30) rem(20);
        }
        &__image-container {
            display: flex;
            justify-content: space-between;
         }
        &__image-info-wrapper {
            width: rem(200);
        }

    }
}