@import "src/styles/lib";

.Item {
    padding: rem(32) 0;
    display: flex;
    &__icon {
        width: rem(20);
        height: rem(20);
        color: $secondary-color;
        &:hover {
            cursor: pointer;
            svg {
                color: darken($secondary-color, 10%);
            }
        }
        svg {
           color: $secondary-color;
        }
    }
    &__main-container {
        border: 1px solid #eeeff2;
        border-radius: rem(12);
        max-width: rem(793);
        width: 100%;
        margin-left: rem(10);
    }
    &__name {
        font-size: rem(16);
        color: #111827;
        line-height: rem(24);
        letter-spacing: 0.3px;
        margin: 0 rem(8) 0 0;
        text-transform: capitalize;
    }
    &__name-id {
        margin-right: rem(16);
        color: #4f5676;
        background-color: #f0f2f9;
        padding: rem(8) rem(12);
        font-size: rem(14);
        border-radius: rem(8);
        text-align: center;
    }
    &__container {
        display: flex;
        align-items: center;
        padding: 0 rem(24);
        height: rem(68);
    }
    &__code-container {
        height: rem(68);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
        color: #5759da;
        padding: 0 rem(24);
        border-radius: 0 0 rem(12) rem(12);
        overflow: hidden;
    }
    &__code-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
   
    &__logs-wrapper {
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }
    &__logs-name {
        font-size: rem(14);
        color: $secondary-color;
        margin-left: rem(3);
    }
    &__copy-container {
        display: flex;
        align-items: center;
        transition: color 300ms ease-in-out;
        position: relative;
        margin-left: rem(5);
        max-width: rem(90);
        width: 100%;
        &:hover {
          cursor: pointer;
          .Item__icon-copy svg, .Item__copy-name {
            color: darken($white, 10%);
          }
        }
    }
    &__icon-copy {
        width: rem(15);
        max-height: rem(18);
        height: 100%;
        margin-right: rem(8);
        margin-bottom: rem(2);
        svg {
            color: $white;
        }
    }
    &__copy-name {
       font-size: rem(16);
       color: $white;
    }
    &__copy-message {
        position: absolute;
        bottom: -20px;
        left: rem(5);
        font-size: rem(14);
        color: $green;
    }
}

@include tablet {
    .Item {
        &__code-container {
            padding-right: 5px;
        }
        &__copy-name {
            font-size: rem(14);
         }

         &__main-container {
            max-width: rem(550);
            width: 100%;
        }
        &__code-wrapper {
            max-width: rem(400);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

@include mobile {
    .Item {
        &__code-container {
            padding-right: 5px;
        }
        &__copy-name {
            font-size: rem(14);
         }

         &__main-container {
            max-width: rem(550);
            width: 100%;
        }
        &__code-wrapper {
            max-width: rem(400);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &__container {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-right: rem(5);
        }
        &__name {
            font-size: rem(14);
        }
        &__name-id {
            font-size: rem(12); 
        }
    }
}