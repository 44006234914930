@import "src/styles/lib";

.NewWebhook {
  &__form {
    background: $white;
    padding: rem(30);
    border-radius: rem(16);
  }
  &__title {
    font-size: rem(22);
    color: #111827;
    margin: 0 0 rem(24) 0;
    font-weight: 700;
  }
  &__subtitle {
    font-size: rem(18);
    line-height: rem(25);
    color: #111827;
    margin-top: 0;
    margin-bottom: rem(32);
    font-weight: 700;
  }
  &__input-group-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: rem(22);
  }
  &__input-group-row2 {
    display: grid;
    grid-template-columns: 1fr 1fr 10%;
    align-items: center;
    gap: rem(22);
  }
  &__form-control {
    margin-bottom: rem(32);
  }
  &__input-group-container {
    position: relative;
  }
  &__input-label {
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: 0.3px;
    font-weight: 600;
    color: #111827;
    margin-bottom: rem(16);
  }
  &__invalid-feedback {
    position: absolute;
    bottom: rem(12);
    left: rem(5);
    font-size: rem(11);
    color: #f06548;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(69);
  }
  &__discard-btn {
    width: rem(120);
    height: rem(48);
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: 0.2px;
    transition: all 200ms ease-in-out;
    margin-right: rem(15);
  }
  &__save-btn {
    width: rem(120);
    height: rem(48);
    color: $white;
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: 0.2px;
    transition: all 200ms ease-in-out;
  }
  &__add-btn {
    margin-top: rem(5);
  }
  &__subtitle-desc {
    color: #718096;
    font-size: rem(14);
    margin-bottom: rem(16);
    margin-top: rem(-16);
    letter-spacing: 0.3px;
  }
  &__triggers-subtitle {
    margin-top: 0;
  }
  &__triggers-wrapper {
    margin: rem(16) 0 rem(32);
  }
  &__headers-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e4f4ff;
    padding: rem(10) rem(16);
    border-radius: rem(4);
    margin-bottom: rem(10);
    max-height: 50px;
    height: 100%;
  }
  &__headers {
    font-size: rem(14);
    color: #0d99ff;
  }
  &__delete-icon {
    width: rem(14);
    height: fit-content;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    svg {
      color: #0d99ff;
    }
    &:hover {
      svg {
        color: $error;
      }
    }
  }
}

@include mobile {
  .NewWebhook {
    &__input-label {
      font-size: rem(14);
    }
    &__input-group-row {
      display: grid;
      grid-template-columns: 1fr;
    }

    &__buttons {
      justify-content: center;
    }
  }
}
