@import "src/styles/lib";

.SignUpForm {
    &__input-group-container{
        position: relative;
    }
    &__input-group-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(16);
       
    }
    &__btn {
        margin-top: rem(32);
        min-width: rem(50);
        width: 100%;
        min-height: rem(38);
        height: rem(56);
        font-size: rem(16);
        border-radius: rem(12);
        border: none;
        padding: rem(8);
        line-height: rem(24);
        letter-spacing: 0.3px;
        cursor: pointer;
        outline: none;
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary-color;
        color: $white;
      
        &:hover {
          background: darken($primary-color, 10%);
        }
        &:disabled {
            opacity: 0.8;
            cursor: not-allowed !important;
          }
      
    }
    
    &__invalid-feedback {
        position: absolute;
        bottom: rem(5);
        left: rem(5);
        font-size: rem(11);
        color: #f06548;
        &--password {
            position: absolute;
            bottom: -2px;
            left: rem(5);
            font-size: rem(11);
            color: #f06548;
        }
    }
    &__form-control {
        margin-bottom: rem(24);
    }
    &__radio-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: rem(5);
        margin-top: rem(10);
    }
    &__spinner {
        margin: 0 auto;
        display: inline-block;
        width: 32px;
        height: auto;
      }
    &__spinner:after {
        content: " ";
        display: block;
        width: 24px;
        height: 24px;
        margin: 8px;
        border-radius: 50%;
        border: 3px solid #ffffff;
        border-color: #ffffff transparent #ffffff transparent;
        animation: spinning 1.2s linear infinite; 
    }
   
}