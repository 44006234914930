@import "src/styles/lib";

.MessageModal {
  max-width: rem(385);
  background-color: $white;

  &__closeIcon {
    width: rem(12);
    height: rem(12);
    display: block;
    margin-left: auto;
    cursor: pointer;
   }

   &__content {
    padding: rem(10) rem(10) rem(20);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    > svg {
      width: rem(40);
      height: rem(40);
    }
    &--danger {
    color: #d70000;
    }
    &--warning {
    color: $warning;
    }
    &--success {
      color: $green;
    }
  }

  &__title {
    text-align: center;
    margin-top: rem(10);
    margin-bottom: rem(15);
    font-weight: 700;
    line-height: rem(24);
  }

  &__paragraph {
    text-align: center;
    font-size: rem(14);
    white-space: break-spaces;
  }
  &__buttons {
    width: 100%;
    margin-top: rem(20);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 rem(20);
    &:first-child {
      margin-right: rem(15);
    }
  }
  &__cancel-button {
    margin-right: rem(15);
    height: rem(50);
  }

  &__accept-button {
    height: rem(50);
    &--danger {
       color: $white;
       background-color: #eb0000;
       &:hover {
        background: darken(#eb0000, 15%);
       }
    }
    &--warning {
      background-color: $warning;
      color: $white;
      &:hover {
        background: darken($warning, 15%);
       }
    }
    &--success {
      background-color: $green;
      color: $white;
      &:hover {
        background: darken($green, 15%);
       }
    }
  }
}


@keyframes fadeOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

