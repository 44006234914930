@import "src/styles/lib";

.Organization {
    width: rem(322);
    height: rem(280);
    border-radius: rem(16);
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: rem(15);
    &:hover {
        cursor: pointer;  
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    &__icon {
        width: rem(17);
        margin-left: rem(5);
    }
    &__link-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    &__link-wrapper span {
        color: #0D99FF;
    }
    &__image {
        width: rem(88);
        height: rem(88);
        border-radius: 50%;
    }
    &__placeholder {
        width: rem(80);
        height: rem(80);
        background-color: #a0aec0;
        border-radius: 50%;
    }
    &__name {
        font-size: rem(20);
        line-height: rem(28);
        font-weight: 700;
        color: #111827;
        margin-bottom: rem(24);
        margin-top: rem(24);
    }

}