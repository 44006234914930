@import "src/styles/lib";

.BouncerContainer {
  width: 100%;
  height: 100vh;
  background-color: $primary-color;
  color: $white;
  padding: rem(122) rem(141) rem(109) rem(141);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__slider {
    position: relative;
    max-width: rem(472);
    width: 100%;
    height: auto;
  }
  &__slide-title,
  &__slide-description {
    text-align: center;
  }
  &__slide-title {
    font-size: rem(40);
    line-height: rem(48);
    font-weight: 700;
  }

  &__fingerprint {
    width: rem(150);
  }

  &__slide-description {
    font-size: rem(18);
    line-height: rem(27);
    font-weight: 500;
    color: #ceefdf;
    padding-bottom: rem(10);
  }

  &__logo {
    display: block;
    margin: 0 auto;
  }
}

@include tablet-big {
  .BouncerContainer {
    padding: rem(122) rem(90) rem(109) rem(90);
  }
}

@include screen-2k {
  .BouncerContainer {
    &__fingerprint {
      width: rem(300);
    }
  }
}
@include screen-4k {
  .BouncerContainer {
    &__fingerprint {
      width: rem(400);
    }
  }
}
