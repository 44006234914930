@import "src/styles/lib";

.AlertModal {
  background-color: $primary-color;
  position: fixed;
  top: 0;
  right: 0;
  left: rem(76);
  min-height: rem(41);
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 500;
  padding: 0 rem(25);
  visibility: hidden;
  opacity: 0;

  &__isMobile {
    left: 0;
  }

  &__isSidebarOpen {
    left: rem(252);
  }

  &__text {
    font-size: rem(13);
    width: 90%;
  }

  &__icon {
    width: rem(20);
    height: rem(20);
    padding: rem(5);
    opacity: 0.5;
    svg {
      color: $white;
    }
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    
  }

  &__error {
    top: rem(96);
    background-color: $error;
    visibility: visible;
    opacity: 1;
    animation: openModal;
    animation-duration: 0.3s;
  }

  &__warning {
    top: rem(96);
    background-color: $warning;
    visibility: visible;
    opacity: 1;
    animation: openModal;
    animation-duration: 0.3s;
  }

  &__success {
    top: rem(96);
    background-color: $green;
    visibility: visible;
    opacity: 1;
    animation: openModal;
    animation-duration: 0.3s;
  }
}

@keyframes openModal {
  from {
    top: rem(0);
    opacity: 0;
  }
  to {
    top: rem(97);
    opacity: 1;
  }
}
