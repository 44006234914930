@import "src/styles/lib";

.Dashboard {
  &__title {
    font-size: rem(22);
    font-weight: bold;
    margin: 0 0 rem(50) 0;
    color: #111827;
  }
}
