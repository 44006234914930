@import "src/styles/lib";

.HeaderOrganization {
  position: relative;
  max-width: rem(350);
  width: 100%;
  &__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: rem(20);
    cursor: pointer;
    padding-right: rem(80);
  }

  &__upload {
    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &--show {
      visibility: visible;
    } 

    &-camera {
      width: rem(18);
      height: rem(18);
      color: $white;
    }
  }

  &__placeholder {
    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    background-color: #a0aec0;
  }
  &__placeholder-name {
    font-size: 16px;
    display: block;
    text-align: center;
    margin: auto;
    padding-top: rem(16);
    color: $white;
    text-transform: uppercase;
  }

  &__image {
    width: rem(48);
    height: rem(48);
    border-radius: 50%;
    object-fit: cover;
    &-container {
      min-height: rem(50);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__icon {
    width: rem(12);
    cursor: pointer;
  }

  &__label {
    font-size: rem(12);
    line-height: rem(19.2);
    font-weight: 400;
    color: #718096;
    cursor: pointer;

    &-container {
      margin-left: rem(16);
      min-height: rem(40);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__name {
    margin-right: rem(8);
    font-size: rem(16);
    line-height: rem(24);
    font-weight: 700;
    letter-spacing: 0.3px;
    color: #111827;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__item-container {
    overflow: hidden;
    width: 100%;
    background: transparent !important;
    color: #111827;
    padding: 0 rem(8);

    &.activeLabel {
      background: #f9fafb;
    }
    &:hover {
      background: #f9fafb;
      cursor: pointer;
    }
  }
  &__item {
    font-size: rem(14);
    line-height: rem(20);
    letter-spacing: 0.2px;
    padding: rem(16);
    font-weight: 400;
    margin: 0;
    border-radius: rem(12);
    display: flex;
    align-items: center;
    &:hover {
      background: #f7f8fb;
    }
  }

  &__image-container {
    position: relative;
    transition: all 300ms ease-in-out;
    &:hover {
      .HeaderOrganization__upload {
        visibility: visible;
        z-index: 2;
      }
    }
  }

  &__item-image {
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    margin-right: rem(10);
  }
  &__item-image-placeholder {
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
    margin-right: rem(10);
    background-color: #a0aec0;
  }
  &__item-image-placeholder-name {
    font-size: 10px;
    display: block;
    text-align: center;
    padding-top: rem(2);
    color: $white;
    text-transform: uppercase;
  }
  &__items {
    margin-top: rem(12);
    overflow-y: auto;
    max-height: rem(300);
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 5;
    display: none;
    justify-content: center;
    border-radius: rem(12);
    background: #ffffff;
    color: #111827;
    padding: rem(12) 0;
    box-shadow: 5px 5px 50px 0px #1a202c0f;

    &.active {
      display: block;
    }
  }
}

@include mobile {
  .HeaderOrganization {
    &__container {
      padding-right: rem(20);
    }
  }
}
