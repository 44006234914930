@import "src/styles/lib";

.NewDeviceForm {
    width: 100%;
    &__title {
        font-size: rem(18);
        line-height: rem(25);
        color: #111827;
        margin-bottom: rem(32);
        margin-top: rem(80);
        font-weight: 700;
    }
    &__form-control {
        margin-bottom: rem(32);
    }
    &__input-group-container {
        position: relative;
    }
    &__input-label {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.3px;
        font-weight: 600;
        color: #111827;
        margin-bottom: rem(16);
    }
    &__invalid-feedback {
        position: absolute;
        bottom: rem(12);
        left: rem(5);
        font-size: rem(11);
        color: #f06548;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: rem(20);
    }
    &__discard-btn {
        width: rem(120);
        height: rem(43);
        color: #0d99ff;
        border: 1px solid #0d99ff;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: 0.2px;
        transition: all 200ms ease-in-out;
        margin-right: rem(15);
        &:hover {
            color: $white;
            background-color: #0d99ff;
        }
    }
    &__save-btn {
        width: rem(120);
        height: rem(43);
        color: $white;
        background-color: #0d99ff;
        font-size: rem(14);
        font-weight: 700;
        letter-spacing: 0.2px;
        transition: all 200ms ease-in-out;
        &:hover {
          background: darken(#0d99ff, 15%);
        }
    }

    &__location-wrapper {
        display: flex;
        align-items: center;
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.3px;
        font-weight: 600;
        color: #111827;
        margin-bottom: rem(20);
    }
    &__icon-wrapper {
        padding: rem(8);
        border-radius: rem(8);
        background-color: $white;
        transition: all 300ms ease-in-out;
        margin-left: rem(30);
        margin-bottom: rem(15);
        &--active {
            background-color: #e4f4ff; 
            cursor: pointer; 
            .NewDeviceForm__icon {
                color:#0D99FF;
            } 
        }
        &:hover {
            background-color: #e4f4ff; 
            cursor: pointer; 
            .NewDeviceForm__icon {
                color:#0D99FF;
            }
        }
        
    }
    &__icon {
        width: rem(32);
        height: rem(32);
        color: #a0aec0;
    }
    &__location-input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: rem(56);
        border: none;
        border-radius: rem(12);
        background-color: #fafafa;
        padding: rem(16);
        &:focus-within {
            border: rem(1) solid #77829E;
            background-color: #ffffff;
            color: #111827;
            font-size: rem(16);
          }
    }
}