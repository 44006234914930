@import "src/styles/lib";

.Organizations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: rem(1400);
    width: 100%;
    margin: 0 auto;
    &__title {
        align-items: center;
        font-size: rem(32);
        line-height: rem(40);
        color: #111827;
        margin-top: rem(110);
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: rem(84) auto rem(100);
    }
    &__create-project {
        width: rem(322);
        height: rem(280);
        border-radius: rem(16);
        background-color: #0D99FF;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: rem(15);
        cursor: pointer;
        &:hover {
           box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
    }
    &__create-project p {
        font-size: rem(20);
        line-height: rem(28);
        font-weight: 700;
    }
    &__icon-container {
        width: rem(75);
        height: rem(75);
        border-radius: 50%;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &__icon {
        width: rem(39);
        height: rem(39); 
        transform: rotate(45deg);

    }
    &__icon svg path {
        stroke: #0D99FF;
    }
    &__spinner {
        margin: rem(200) auto;
        display: inline-block;
        width: 80px;
        height: 80px;
      }
      &__spinner:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $primary-color;
        border-color: $primary-color transparent $primary-color transparent;
        animation: spinning 1.2s linear infinite;
      }
}

@include laptop {
    .Organizations {
        &__container {
            margin-top: rem(64);
        }
    }
}