@import "src/styles/lib";

.Webhook {
    padding: rem(32) 0;
    display: flex;
    &__icon {
        width: rem(20);
        height: rem(20);
        color: $secondary-color;
        &:hover {
            cursor: pointer;
            svg {
                color: darken($secondary-color, 10%);
            }
        }
        svg {
           color: $secondary-color;
        }
    }
    &__icons-wrapper {
       display: flex;
    }
    &__delete-icon-wrapper {
        width: rem(18);
        height: rem(18);
        margin: 0 rem(15);
        cursor: pointer;
    } 
    &__delete-icon {
        width: rem(18);
        height: fit-content;
        transition: all 200ms ease-in-out;
        svg {
          color: $error;
        }
        &:hover {
            svg {
             color: darken($error, 10%);
            } 
        }
    }
    &__main-container {
        border: 1px solid #eeeff2;
        border-radius: rem(12);
        max-width: rem(793);
        width: 100%;
        margin-left: rem(10);
    }
    &__name {
        font-size: rem(16);
        color: #111827;
        line-height: rem(24);
        letter-spacing: 0.3px;
        margin: 0 rem(16) 0 0;
        text-transform: capitalize;
    }
    &__container {
        display: flex;
        align-items: center;
        padding: 0 rem(24);
        height: rem(68);
    }
    &__code-container {
        height: rem(68);
        display: flex;
        align-items: center;
        background-color: #000000;
        color: #5759da;
        padding: 0 rem(24);
        border-radius: 0 0 rem(12) rem(12);
        overflow: hidden;
    }
    &__state-wrapper {
        display: flex;
        margin-left: auto;
    }
    &__state-circle {
       width: rem(16);
       height: rem(16);
       border-radius: 50%;
       background-color: $secondary-color;
       margin-right: rem(10);
       &--active {
         background-color: $green;
       }
    }
    &__state {
        color: $secondary-color;
        font-size: rem(14);
        &--active {
            color: $green;
        }
    }
    &__logs-wrapper {
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }
    &__logs-name {
        font-size: rem(14);
        color: $secondary-color;
        margin-left: rem(3);
    }
}

@include tablet {
    .Webhook {
        &__container  {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        &__state-wrapper {
            margin-top: rem(-18);
        } 
        &__name {
            margin-bottom: rem(10);
        }
        &__main-container {
            max-width: rem(493);
            width: 100%;
        }
    }
}

@include mobile {
    .Webhook {
        margin-left: rem(-18);
        &__container  {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        &__state-wrapper {
            margin-top: rem(-18);
        } 
        &__name {
            margin-bottom: rem(10);
        }
        &__main-container {
            min-width: rem(300);
            max-width: rem(500);
            width: 100%;
            margin-left: 0;
        }
    }
}