@import "../../../styles/lib";

.RadioButton {
  display: flex;
  align-items: center;
  width: fit-content;


  &:hover > &__label {
    color: #000000;
    transition: 0.3s;
  }
 /*  &:hover > &__circle {
    border: 1px solid #94a1ae;
    transition: 0.3s;
  } */

  &:hover > &__circle--disabled {
    border: rem(1) solid #94a1ae;
  }
  &:hover > &__label--disabled {
    color: #9599ad;
  }
  &:hover > &__label--disabled-checked {
    color: #9599ad;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: rem(1) solid #94a1ae;
    border-radius: 50%;
    height: rem(20);
    width: rem(20);
    min-width: rem(20);
    padding-top: rem(3);
    padding-left: rem(1);
    cursor: pointer;

    &--checked {
     border: rem(1) solid $green;
     background-color: $green;
    }
    &--disabled {
      opacity: 0.4;
      cursor: default;
      &-checked {
        opacity: 0.4;
        cursor: default;
      }
    }
  }

  &__icon {
    height: rem(12);
    width: rem(12);
    min-width: rem(12);
  }

  &__label {
    color: #1E2945;
    font-size: rem(16);
    cursor: pointer;
    padding-left: rem(12);

    &--disabled {
      opacity: 0.4;
      cursor: default;
      &-checked {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}

@include mobile {
  .RadioButton {
    &__label {
        font-size: 12px;
        padding-left: rem(10);
      } 
  }
}