@import "src/styles/lib";

.LocationModal {
   max-width: rem(820);
   width: 100%;
   padding: rem(12);
   display: flex;
   flex-direction: column;
   align-items: center;
   
   &__icon {
    width: rem(12);
    height: rem(12);
    display: block;
    margin-left: auto;
    cursor: pointer;
    margin-bottom: rem(12);
   }
   &__btn {
      margin-left: rem(12);
     // width: rem(260);
   }
  
   &__buttons-wrapper {
      margin-top: rem(20);
      display: flex;
      align-items: center;
      width: rem(280);
   }

  &__container {
    min-height: rem(400);
    height: rem(400);
    min-width: rem(700);
    width: auto;
    position: relative;
  }
}

@include tablet {
  .LocationModal {
    &__container {
      min-width: rem(550);
      width: auto;
    }
  }
}

@include mobile {
  .Map {
    &__container {
      min-width: rem(310);
      overflow: hidden;
      width: auto;
    }
  }
}