@import "src/styles/lib";

.Button {
  min-width: rem(50);
  width: 100%;
  min-height: rem(38);
  height: rem(56);
  font-size: rem(16);
  border-radius: rem(12);
  border: none;
  padding: rem(8);
  line-height: rem(24);
  letter-spacing: 0.3px;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color;
  color: $white;
  &[disabled=disabled], &:disabled {
    opacity: 0.8;
    cursor: not-allowed !important; 
  }
  &:hover {
    background: darken($primary-color, 10%);
  }
  &__icon {
    svg {
      width: rem(24);
      height: rem(24);
    }
  }
  &__spinner {
    margin: 0 auto;
    display: inline-block;
    //width: 32px;
    height: auto;
  }
  &__spinner:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: spinning 1.2s linear infinite; 
  }

  &__label {
    margin: 0 rem(5);
    white-space: nowrap;
  }

  &--disabled {
    opacity: 0.8;
    cursor: not-allowed !important;
  }

  &__primary {
    background: $primary-color;
    color: $white;
    &:hover {
      background: darken($primary-color, 10%);
    }
    &--outline {
      background: transparent;
      border: 1px solid $primary-color;
      color: $primary-color;
      &:hover {
        background: $primary-color;
        color: $white;
      }
    }
  }

}

@include mobile {
  .Button {
    height: rem(50);
  }
}