@import "src/styles/lib";

.FileUpload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: rem(400);
  width: rem(500);

  &__title {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(13);
  }
  &__subtitle {
    color: $primary-color;
    font-size: rem(12);
    line-height: rem(18);
    opacity: 0.7;
    margin-top: rem(3);
  }

  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: rem(30) 0 rem(20) 0;
    border: 1px solid #e9ebec;
    background-color: #F5F5F5;
  }

  &__error {
    color: #f06548;
    font-size: rem(14);
    margin-bottom: rem(10);
  }

  &__input-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      background: repeating-linear-gradient(
        -45deg,
        rgba(97, 181, 228, 0.2),
        rgba(97, 181, 228, 0.2) 10px,
        $white 10px,
        $white 20px
      );

      background-size: 200% 200%;
      animation: barberpole 10s linear infinite;

      @keyframes barberpole {
        100% {
          background-position: 100% 100%;
        }
      }
    }
  }

  &__paragraph {
    width: rem(250);
  }

  &__image-container {
    background-color: #F5F5F5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__remove {
    visibility: hidden;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-button {
      position: absolute;
      top: rem(10);
      right: rem(10);
      background: transparent;
      border: rem(1) solid $white;
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      font-size: rem(13);
      padding: rem(2) rem(4);

      &:hover {
        cursor: pointer;
      }
    }

    &-paragraph {
      color: $white;
      max-width: rem(200);
      word-wrap: break-word;
    }
  }

  &__remove--show {
    visibility: visible;
  }

  &__footer {
    display: flex;
    max-width: rem(200);
    width: 100%;
  }

  &__button {
    margin-right: rem(15);
  }
}