@import "src/styles/lib";
.SelectBox2 {
  min-width: rem(80);
  border: 1px solid #ced4da;
  position: relative;
  border-radius: rem(4);
  &--active {
    background-color: $primary-color;
    color: $white;
}
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    height: 100%;
    color: #000000;
    font-weight: 400;
  //  padding: rem(9) rem(15);
   padding: rem(4) rem(3) rem(3) rem(8);
    &--disabled {
      background: #f3f3f9;
      cursor: not-allowed;
    }
  }
  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(13);
    font-weight: 600;
    padding: rem(9) rem(15) rem(9) rem(4);
    &--active {
        color: $white;
    }
    &--disabled {
        color: #ADB5BD;
    }
  }
  &__icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(19);
    color: #000000;
    &--active {
        color: $white;
    }
    &--disabled {
      color: #ADB5BD;
  }
  }
  &__body {
    margin-top: rem(10);
    overflow-y: auto;
    max-height: rem(200);
    width: 100%;
    position: absolute;
    z-index: 5;
    display: none;
    border: 1px solid #ced4da;
    justify-content: center;
    background: $white;
    border-radius: rem(4);
    &--active {
      display: block;
    }
  }
  &__item {
    padding: rem(5);
    &-label {
      padding: rem(8) rem(15);
      color: $primary-color;
      font-size: rem(13);
      border-radius: rem(4);
      &:hover {
        color: $white;
        background: lighten($primary-color, 10%);
        cursor: pointer;
      }
      &--active {
        color: $white;
        background: $primary-color;
      }
    }
  }
  &__spinner {
    padding: rem(9) rem(15);
    color: #ffffff;
    &::after {
     content: " ";
     display: block;
     width: 11px;
     height: 11px;
     border-radius: 50%;
     border: 2px solid #ffffff;
     border-color: #ffffff transparent #ffffff transparent;
     animation: spinning 1.2s linear infinite;
    }
 }
}