@import "src/styles/lib";

.RightSidebar {
  position: fixed; 
  display: none; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 502; 
 
  &__container {
    max-width: rem(500);
    width: 100%;
    height: 100%;
    background-color: $white;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 502;
    border-radius: rem(16) 0 0 rem(16);
    flex-direction: column;
    align-items: center;
    box-shadow: 0 rem(1) rem(30) rem(1) rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e4e4;
    overflow-y: auto;
  }

  &--opened {
    display: flex;
  }
  &__header {
    position: relative;
    width: 100%;
  }
  &__close {
    position: absolute;
    left: 24px;
    top: 24px;
  }
  &__close svg {
    width: rem(20);
    height: rem(20);
    color: $secondary-color;
    &:hover {
      cursor: pointer;
      color: $color-default;
    }
  }
  &__refresh {
    width: rem(20);
    height: rem(20);
    &:hover {
      cursor: pointer;
      color: $color-default;
    }
  }
  &__body {
    padding: rem(24);
    width: 100%;
  }
}

@include mobile {
  .RightSidebar {
    width: 100%;
    height: 100%;

    &__body {
      width: 100%;
    }
  }
}
