@import "src/styles/lib";

.LogDetails {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(30);
  }
  &__header-title {
    font-size: rem(22);
    color: #111827;
    text-transform: capitalize;
    margin: 0 0 rem(10) 0;
  }
  &__header-subtitle {
    font-size: rem(16);
    color: $secondary-color;
    span {
      color: #0d99ff;
    }
  }
  &__container {
    min-height: rem(700);
    width: 100%;
    background: $white;
    border-radius: rem(16);
    padding: rem(24) 0;
  }
  &__log-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    gap: rem(40);
    padding: 0 rem(42) rem(24);
    border-bottom: 1px solid #eeeff2;
    p {
      font-size: rem(16);
      color: #718096;
      line-height: rem(24);
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  &__log {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
    gap: rem(30);
    padding: rem(16) rem(42) rem(16);
    margin-bottom: rem(60);
    &:hover {
      cursor: pointer;
    }
  }

  &__success {
    color: #42de96;
  }
  &__fail {
    color: $error;
  }
  &__time {
    color: $secondary-color;
    font-size: rem(16);
    letter-spacing: 0.3px;
    line-height: rem(24);
  }
  &__date {
    color: #111827;
    font-size: rem(16);
    letter-spacing: 0.3px;
    line-height: rem(24);
  }
  &__device {
    color: #4f5676;
    background-color: #f0f2f9;
    padding: rem(8) rem(12);
    font-size: rem(14);
    border-radius: rem(8);
    text-align: center;
    min-width: rem(118);
    width: 100%;
  }
  &__status-code {
    display: flex;
    align-items: center;
  }
  &__status-icon {
    background-color: #4bae4f;
    width: rem(18);
    height: rem(18);
    border-radius: 50%;
    padding-top: rem(4);
    padding-left: rem(4);
    margin-right: rem(5);
    svg {
      width: rem(10);
      height: rem(10);
    }
  }
  &__status-error-icon {
    background-color: $error;
    width: rem(18);
    height: rem(18);
    border-radius: 50%;
    padding-top: rem(5);
    padding-left: rem(5);
    margin-right: rem(5);
    svg {
      width: rem(8);
      height: fit-content;
      color: $white;
    }
  }
  &__duration {
    color: #111827;
  }
  &__code-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem(60);
    padding: 0 rem(24);
  }
}

@include tablet {
  .LogDetails {
    &__log-header {
      padding: 0 rem(10) rem(24);
      gap: 5%;
    }
    &__date,
    &__time {
      font-size: rem(14);
    }
    &__log {
      gap: 5%;
      margin-bottom: rem(30);
      padding: 10px;
      font-size: rem(14);
    }
    &__code-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: rem(60);
      padding: 0 rem(24);
    }
    &__status-code {
      flex-direction: column;
      margin-left: 0;
    }
    &__status-icon,
    &__status-error-icon {
      margin-bottom: rem(5);
    }
    &__device-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 100px;
      width: 100%;
    }
    &__device {
      color: #4f5676;
      background-color: #f0f2f9;
      padding: rem(8) rem(12);
      font-size: rem(12);
      border-radius: rem(8);
      text-align: center;
      min-width: rem(90);
      width: 100%;
      overflow-wrap: break-word;
    }
  }
}

@include mobile {
  .LogDetails {
    &__log-header {
      padding: 0 rem(10) rem(24);
      gap: 5%;
    }
    &__log {
      gap: 5%;
      margin-bottom: rem(30);
      padding: 10px;
    }
    &__code-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: rem(60);
      padding: 0 rem(24);
    }
    &__status-code {
      flex-direction: column;
      margin-left: 10px;
    }
    &__status-icon,
    &__status-error-icon {
      margin-bottom: rem(5);
    }
    &__device-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 100px;
      width: 100%;
    }
    &__device {
      color: #4f5676;
      background-color: #f0f2f9;
      padding: rem(8) rem(12);
      font-size: rem(12);
      border-radius: rem(8);
      text-align: center;
      min-width: rem(90);
      width: 100%;
      overflow-wrap: break-word;
    }
  }
}
