@import "src/styles/lib";

.Profile {
  &__header {
    font-size: rem(22);
    font-weight: bold;
    color: #111827;
    margin: 0 0 rem(24) 0;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  &__sidebar {
    height: fit-content;
    background-color: $white;
    padding: rem(30);
    margin-right: rem(32);
    border-radius: rem(16);
  }

  &__sidebar-item {
    display: flex;
    align-items: center;
    padding: rem(16) rem(21);
    transition: all 300ms ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: #fafafa;
      border-radius: rem(12);
    }
    &--active {
      background-color: #fafafa;
      border-radius: rem(12);
    }
  }
  &__sidebar-item:hover &__icon-wrapper {
    background-color: $white;
  }
  &__sidebar-item-title {
    font-size: rem(14);
    font-weight: 700;
    color: #111827;
    letter-spacing: 0.2px;
    line-height: 22.4px;
    margin: 0;
  }
  &__sidebar-item-subtitle {
    font-size: rem(12);
    font-weight: 400;
    color: #718096;
    letter-spacing: 0.2px;
    line-height: 19.2px;
    margin-top: 0;
  }
  &__icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fafafa;
    margin-right: rem(16);
    padding: rem(11) rem(12);
    &:hover {
      background-color: $white;
    }
    &--active {
      background-color: $white;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
  }
  &__card {
    background-color: $white;
    padding: rem(30);
    border-radius: rem(16);
  }
  &__card-title {
    font-size: rem(20);
    line-height: rem(28);
    color: #111827;
    padding-bottom: rem(24);
    border-bottom: 1px solid #f1f2f4;
    margin-bottom: rem(32);
  }
  &__image {
    width: rem(88);
    height: rem(88);
    object-fit: cover;
    margin-right: rem(24);
    border-radius: 50%;
}

&__image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem(70);
    position: relative;
    &:hover {
      .Profile__upload {
        visibility: visible;
        z-index: 2;
        cursor: pointer;
      }
    }
}

&__upload {
  width: rem(88);
  height: rem(88);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &--show {
    visibility: visible;
  } 

  &-camera {
    width: rem(38);
    height: rem(38);
    color: $white;
  }
}

&__placeholder {
    width: rem(88);
    height: rem(88);
    border-radius: 50%;
    background-color: #a0aec0;
    margin-right: rem(24);
    cursor: pointer;
  }
  &__placeholder-name {
    font-size: 32px;
    display: block;
    text-align: center;
    margin: auto;
    padding-top: rem(24);
    color: $white;
    text-transform: uppercase;
  }
  &__description {
    font-size: rem(14);
    line-height: rem(22.4);
    color: #718096;
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
  }
  &__button {
    background-color: #0d99ff;
    width: rem(165);
    height: rem(48);
    font-size: rem(14);
    letter-spacing: 0.2px;
    line-height: rem(22.4);
    margin-top: rem(16);
    margin-right: rem(8);
    &:hover {
      background: darken(#0d99ff, 10%);
    }
  }
  &__delete-img {
    width: rem(128);
    height: rem(48);
    color: #0d99ff;
    padding-top: 20px;
    font-size: rem(14);
    letter-spacing: 0.2px;
    line-height: rem(22.4);
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    transition: color 300ms ease-in-out;
    &:hover {
      color: darken(#0d99ff, 10%);
    }
    &--disabled {
       cursor: not-allowed;
       color: #1E2945;
       opacity: 0.8;
    }
  }
}

@include mobile {
  .Profile {
    &__container {
      grid-template-columns: 1fr;
    }

    &__sidebar {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
    &__card {
      margin: 0;
    }
    &__image-wrapper {
      flex-direction: column;
    }
    &__image {
        margin-bottom: rem(10);
    }
    &__placeholder {
      margin-right: 0;
    }
    &__image {
      margin-right: 0;
    }
  }
}
