@import "src/styles/lib";

.PersonalInfoForm {
    &__input-group-container{
        position: relative;
    }
    &__input-group-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: rem(24);
    }
    &__btn {
        margin-top: rem(66);
        max-width: rem(126);
        width: 100%;
        height: rem(48);
        font-size: rem(14);
        border-radius: rem(12);
        border: none;
        padding: rem(8);
        line-height: rem(24);
        letter-spacing: 0.3px;
        cursor: pointer;
        outline: none;
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $primary-color;
        color: $white;
        margin-left: auto;
      
        &:hover {
          background: darken($primary-color, 10%);
        }
        &:disabled {
            opacity: 0.8;
            cursor: not-allowed !important;
        }
    }

    &__form-control-label {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.2px;
        color: #718096;
        margin-bottom: rem(12);
    }
    
    &__invalid-feedback {
        position: absolute;
        bottom: rem(5);
        left: rem(5);
        font-size: rem(11);
        color: #f06548;
        &--password {
            position: absolute;
            bottom: -2px;
            left: rem(5);
            font-size: rem(11);
            color: #f06548;
        }
    }
    &__form-control {
        margin-bottom: rem(24);
    }
    &__spinner {
        margin: 0 auto;
        display: inline-block;
        width: 28px;
        height: auto;
      }
    &__spinner:after {
        content: " ";
        display: block;
        width: 24px;
        height: 24px;
        margin: 0;
        border-radius: 50%;
        border: 3px solid #ffffff;
        border-color: #ffffff transparent #ffffff transparent;
        animation: spinning 1.2s linear infinite; 
    }
}

@include mobile {
    .PersonalInfoForm {
        &__form {
            padding-bottom: 60px;
        }
        &__input-group-row {
            display: grid;
            grid-template-columns: 1fr;
            gap: rem(16);
        }
    }
}