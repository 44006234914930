@import "src/styles/lib";

.DropDownWithImg {
    height: rem(56);
    border: none;
    border-radius: rem(12);
    background-color: #fafafa;
    padding: rem(16);
    min-width: rem(103);
    width: 100%;
    position: relative;
    &__required {
        border: 1px solid #f06548;
    }
    &:focus-within {
        border: rem(1) solid #77829E;
        background-color: #ffffff;
        color: #111827;
        transition: 0.3s;
    }
    &__item {
        font-size: rem(16);
        line-height: rem(24);
        letter-spacing: 0.2px;
        padding: rem(16);
        font-weight: 400;
        margin: 0;
        border-radius: rem(12);
        display: flex;
        align-items: center;
        &:hover {
        background: #f7f8fb;
        }
    }
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: rem(12);
        position: relative;
        cursor: default;
        height: 100%;
        color:  #111827 !important;
      //  padding: 0 rem(10);
    }
    &__input {
        background: transparent;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #212529;
        display: flex;
        align-items: center;
        cursor: default;
        font-size: rem(16);
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: rem(10);
        color: #212529;
        cursor: pointer;
        & > svg {
        width: 20px;
        height: 20px;
        }
        &:hover {
        transition: 0.3s;
        color: $primary-color;
        }
    }
    &__items {
        margin-top: rem(22);
        overflow-y: auto;
        max-height: rem(300);
        width: 100%;
        position: absolute;
        left: 0;
        z-index: 5;
        display: none;
        justify-content: center;
        border-radius: rem(12);
        background: #ffffff;
        color: #111827;
        padding: 0px, 8px, 0px, 8px;
        box-shadow: 5px 5px 50px 0px #1A202C0F;
        
        &.active {
        display: block;
        }
    }
    &__image {
       font-size: rem(24);
        border-radius: 50%;
        margin-right: rem(16);
    }
    &__input-flag {
        margin-right: rem(10); 
        font-size: rem(24);
    }
    &__item-container {
        overflow: hidden;
        width: 100%;
        background: transparent !important;
        color: #111827;
        padding: 0 rem(8);
      
        &.activeLabel {
        background: #F9FAFB;
        }
        &:hover {
         background: #F9FAFB;
         cursor: pointer;
        }
    }
}