@import "src/styles/lib";

.SignUp {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 52.5% 47.5%;
    &__footer {
        margin-top: auto;
        margin-bottom: rem(40);
        font-size: rem(16);
        line-height: rem(24);
        color: $secondary-color;
        text-align: center;
        &-link {
            color: $primary-color;
            font-weight: 700;
            margin-left: rem(5);
            text-decoration: none;
            &:hover {
                color: darken($primary-color, 20%); 
            }
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        padding: rem(207) rem(118) 0;
        position: relative;
        background-color: $white;
    }
    &__wrapper {
        margin-bottom: rem(30);
    }
  
    &__title {
        font-size: rem(32);
        line-height: rem(40);
        font-weight: 700;
        color: #111827;
        margin-bottom: rem(40);
    }
}
@include tablet-big {
    .SignUp {
        &__container {
            padding: rem(207) rem(60) 0;
        } 
        &__footer {
            margin-bottom: rem(20);
        }
    }
}

@include tablet {
    .SignUp {
        grid-template-columns: 100%; 
        height: 100vh;
        &__container {
            justify-content: unset;
            padding: 0 rem(60);
        }
        &__logo-wrapper {
           margin: rem(87) auto rem(70);
        }
        &__title {
            font-size: rem(26);
            line-height: rem(32);
            text-align: center;
        }
        &__footer {
            margin-bottom: rem(20);
        }
    }
}
@include mobile {
    .SignUp {
        grid-template-columns: 100%; 
        height: 100vh;
        &__container {
            justify-content: unset;
            padding: 0 rem(40);
        }
        &__logo-wrapper {
           margin: rem(87) auto rem(70);
        }
        &__title {
            font-size: rem(26);
            line-height: rem(32);
            text-align: center;
        }
        &__footer {
            margin-bottom: rem(20);
        }
    }
}