@import "src/styles/lib";

/* .SwitchButton {
  display: flex;
  width: 100%;
  min-width: rem(150);
  align-items: center;
  &--disabled {
    opacity: 0.3;
    cursor: auto;
  }

  &__switch {
    width: rem(26);
    min-width: rem(26);
    height: rem(14);
    background-color: #ffffff;
    border: 1px solid #718096;
    border-radius: rem(28);
    cursor: pointer;
    &--active {
      background-color: $primary-color;
      border: 1px solid $primary-color;
    }
  }

  &__label {
    margin-left: rem(14);
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  &__circle {
    width: rem(8);
    height: rem(8);
    border-radius: rem(25);
    background-color: #718096;
    margin: 2px;

    &--active {
      background-color: #ffffff;
      float: right;
    }
  }
}
 */
.SwitchButton {
    display: flex;
    width: 100%;
   // min-width: rem(150);
    align-items: center;
  
    &--disabled {
      opacity: 0.3;
      cursor: auto;
    }
  
    &__switch {
      width: rem(44);
      min-width: rem(44);
      height: rem(24);
      background-color: #EEEFF2;
      border-radius: rem(23);
      cursor: pointer;
      &--active {
        background-color: $primary-color;
      }
    }
  
    &__label {
      margin-left: rem(14);
      margin-bottom: 0 !important;
      cursor: pointer;
      margin-right: rem(14);
    }
  
    &__circle {
      width: rem(20);
      height: rem(20);
      border-radius: 50%;
      background-color: $white;
      margin: 2px;
      &--active {
        float: right;
      }
    }
  }