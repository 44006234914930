@import "src/styles/lib";

.MultipleSelectBox {
  min-height: rem(56);
  height: 100%;
  min-width: rem(59);
  border: rem(1) solid #77829E;
  background-color: #ffffff;
  color: #111827;
  border-radius: 8px;
  position: relative;
  &:focus-within {
    border: rem(1) solid #77829E;
    background-color: #ffffff;
    color: #111827;
}

  &__item {
    font-size: rem(14);
    line-height: rem(24);
    font-weight: 400;
    margin: 0;
    padding: rem(16);
    &:hover {
        background: #F9FAFB;
        border-radius: rem(6);
        cursor: pointer;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: rem(10);
    align-items: center;
    flex-wrap: wrap;
    border-radius: rem(12);
    position: relative;
    cursor: pointer;
    height: 100%;
    font-weight: 400;
    padding: rem(2) rem(10) 0 rem(16);
    min-height: rem(50);
    &:focus-within {
      border: rem(1) solid #77829E;
      background-color: #ffffff;
      color: #111827;
      transition: 0.3s;
  }
  }

  &__container2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    background-color: #eceef2;
    padding: rem(12);
    border-radius: rem(12);
    margin: rem(5);
    transition: all 200ms ease-in-out;
    &:hover {
        background: darken(#eceef2, 5%);
    }
  }

  &__label-icon {
    width: rem(10);
    height: rem(10);
    margin-left: rem(10);
  }

  &__label {
    display: flex;
    font-size: rem(14);
    color: #111827;
    font-weight: 600;
  }

  &__icon {
    cursor: pointer;
    padding-top: rem(2);
    
    > svg {
      width: rem(16);
    }
  }

  &__items {
    margin-top: rem(6);
    overflow-y: auto;
    max-height: rem(300);
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 5;
    display: none;
    justify-content: center;
    border-radius: rem(6);
    background: #ffffff;
    color: #111827;
    padding: 0px, 8px, 0px, 8px;
    box-shadow: 5px 5px 50px 0px #1A202C0F;

    &.active {
      display: block;
    }
  }

  &__item-container {
    line-height: rem(21);
    overflow: hidden;
    width: 100%;
    background: transparent !important;
    color: #111827;
    padding: rem(4) rem(8);
    &.activeLabel {
      background: #F9FAFB;
    }
    &:hover {
      background: #F9FAFB;
      cursor: pointer;
    }
  }
}
