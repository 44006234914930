@import "src/styles/lib";

.EmailVerification {
    width: 100%;
    height: 100vh;
    background-color: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
  &__container {
    max-width: rem(552);
    width: 100%;
    padding: rem(40);
    background-color: $white;
    border-radius: rem(24);
    margin: rem(20);
  }
  &__spinner {
    margin: 0 auto;
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  &__spinner:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $primary-color;
    border-color: $primary-color transparent $primary-color transparent;
    animation: spinning 1.2s linear infinite;
  }
}


@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}