@import "src/styles/lib";

.SecureKeyLogs {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(24);
  }
  &__header-title {
    font-size: rem(22);
    font-weight: bold;
    margin: 0;
    color: #111827;
    text-transform: capitalize;
  }
  &__container {
    //min-height: rem(700);
    width: 100%;
    background: $white;
    border-radius: rem(16);
    padding: rem(24) 0;
  }
  &__log-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(40);
   // padding: 0 rem(42) rem(24);
    padding: 0 rem(28) rem(24);
    border-bottom: 1px solid #eeeff2;
    p {
      font-size: rem(16);
      color: #718096;
      line-height: rem(24);
      letter-spacing: 0.2px;
      margin: 0;
    }
  }
  &__log {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
   // justify-items: center;
    gap: rem(30);
    padding: rem(16) rem(42) rem(16) rem(30);
    border-bottom: 1px solid #eeeff2;
    &:hover {
      cursor: pointer;
      background-color: #fafafa;
    }
  }
  &__no-content {
    text-align: center;
    margin: rem(180) auto;
    font-size: rem(24);
    color: $primary-color;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  &__time {
    color: $secondary-color;
    font-size: rem(16);
    letter-spacing: 0.3px;
    line-height: rem(24);
  }
  &__date {
    color: #111827;
    font-size: rem(16);
    letter-spacing: 0.3px;
    line-height: rem(24);
  }
  &__age {
    color: #4f5676;
    background-color: #f0f2f9;
    padding: rem(8) rem(12);
    font-size: rem(14);
    border-radius: rem(8);
    text-align: center;
    max-width: rem(56);
    width: 100%;
    margin-left: rem(16);
  }
  &__token-container {
    position: relative;
    max-width: rem(180);
    &:hover {
        background-color: #f0f2f9;
        padding: rem(8) rem(12);
        border-radius: rem(8);
        .SecureKeyLogs__icon-copy {
            visibility: visible;
        }
    }
  }
  &__token-value {
    max-width: rem(150);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon-copy {
    width: rem(13);
    max-height: rem(18);
    height: 100%;
    margin-left: rem(2);
    visibility: hidden;
   svg {
    color: #111827;
   }
  }
  &__copy-message {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: rem(14);
    color: $green;
  }
  &__duration {
    color: #111827;
    margin-left: rem(10);
  }

  &__pagination {
    margin-top: rem(20);
    margin-bottom: rem(50);
    padding: rem(20) rem(10);
    background: $white;
    border-radius: rem(16);
  }
}

@include tablet {
    .SecureKeyLogs {
      padding: rem(40) rem(20);
      &__log-header {
        padding: 0 rem(8) rem(24);
        gap: 5%;
        p {
            font-size: rem(14);   
        }
      }
      &__token-value {
        max-width: rem(100);
        width: 100%;
      }
      &__date,
      &__time {
        font-size: rem(14);
      }
      &__log {
        gap: 5%;
        padding: 10px;
        font-size: rem(14);
      }
    }
  }
  
  @include mobile {
    .SecureKeyLogs {
        padding:  rem(20) rem(10);
        &__log-header {
          padding: 0 rem(8) rem(24);
          gap: 5%;
          p {
              font-size: rem(14);   
          }
        }
        &__token-value {
          max-width: rem(100);
          width: 100%;
        }
        &__date,
        &__time {
          font-size: rem(14);
        }
        &__log {
          gap: 5%;
          padding: 10px;
          font-size: rem(14);
        }
        &__age {
          margin-left: rem(4);
        }
        &__token-container {
          max-width: rem(110);
        }
      }
  }
  
