@import "src/styles/lib";

.NotificationModal {
   max-width: rem(520);
   width: 100%;
   padding: rem(12);
   display: flex;
   flex-direction: column;
   align-items: center;
   
   &__icon {
    width: rem(12);
    height: rem(12);
    display: block;
    margin-left: auto;
    cursor: pointer;
   }
   &__image {
      max-width: rem(146.5);
      width: 100%;
      height: auto;
      margin: 0 auto;
      margin-bottom: rem(32);
   }
   &__title {
    font-size: rem(32);
    line-height: rem(40);
    font-weight: 700;
    color: #111827;
    margin-bottom: rem(16);
    margin-top: 0;
    text-align: center;
    padding: 0 rem(20);
   }
   &__subtitle {
    font-size: rem(18);
    line-height: rem(27);
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #718096;
    margin-bottom: rem(40);
    margin-top: 0;
    text-align: center;
    padding: 0 rem(20);
   }
}

@include mobile {
   .NotificationModal {
      &__title, &__subtitle {
         padding: 0;
      }
      &__title {
         font-size: rem(24);
         line-height: rem(30);
      }
      &__subtitle {
         font-size: rem(16);
      }
   }
}