@import "src/styles/lib";

.InputField {
  display: inline-block;
  width: 100%;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: rem(56);
    border: none;
    border-radius: rem(12);
    background-color: #fafafa;
    padding: rem(16);
    &--error {
      border: rem(1) solid #FF4861;
    }

    &--disabled {
      background: darken(#fafafa, 10%);
      cursor: not-allowed;
      color: #111827;
      opacity: 0.4;
    }

    &:focus-within {
      border: rem(1) solid #77829E;
      background-color: #ffffff;
      color: #111827;
      font-size: rem(16);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(5);
    background: transparent;

    > svg {
      width: rem(24);
      height: rem(24);
    }

    &--right {
      margin-right: rem(10);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__input {
    width: 100%;
    font-size: rem(16);
    line-height: rem(24);
    letter-spacing: 0.2px;
    font-weight: 400;
    height: rem(56);
    border-radius: rem(12);
    color: #111827;
    outline: none;
    border: none;
    background: transparent;
    &--disabled {
     cursor: not-allowed;
    }
    &::placeholder {
     color: #A0AEC0;
     font-weight: 400;
    }
  }
}

@include mobile {
  .InputField {
    &__input {
      font-size: 16px;

      &::placeholder {
        font-size: 13px;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}