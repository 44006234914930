@import "src/styles/lib";

.AuthAlert {
    max-width: rem(449);
    width: 100%;
    border: 1px solid #2196F3;
    padding: rem(16) rem(16) rem(16) rem(48);
    background-color: #E3F2FD;
    border-radius: rem(8);
    margin: 0 auto;
    z-index: 50;
    position: absolute;
    top: 60px;
    animation-iteration-count: 1;
    animation: slide 0.5s linear;

    &--error {
        border: 1px solid $error;  
        background-color: #f8d7da;
    }
    &--success {
        border: 1px solid #4BB543;  
        background-color: #def1de;
    }

    &__header {
        display: flex;
        align-items: center;
    }
    &__header-title{
        margin: 0;
        padding: 0;
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(20);
        color: #263238;
    }
    &__icon {
        width: 24px;
        height: 24px; 
        margin-right: rem(8);
        margin-left: -35px;
        &--error {
            color: #f06548;    
        }
        &--success {
            color: #4BB543;  
        }
    }
    &__subtitle {
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(20);
        color: #546E7A;
        margin: 0;
        padding: rem(4) 0;
    }
}

@keyframes slide {
    0% {
      top: 0px;
    }
    100% {
      top: 60px;
    }
}

@include tablet {
    .AuthAlert {
        left: 200px;
    }
}

@include mobile {
    .AuthAlert {
        width: 80%;
        left: 40px;
    }
}